<template>
    <div class="wrapper flex flex-column">
        <Header :navList="navList" navCurrentId="2" :logo="logoUrl"></Header>
        <div class="flex-item">
            <div class="filter-wrapper" v-if="subjectList.length">
                <filter-tab title="学科" :selected="subjectIndex" :list="subjectList" :isShowMore="true" @clickItem="handleFilterEvent('subjectIndex',$event)"></filter-tab>
            </div>
             <!-- 筛选条件 -->
            <div v-if="subjectIndex != -1 " class="filter-condition active">
                <div class="container flex y-center x-left">
                    <div class="search-text">搜索条件：</div>
                    <a-breadcrumb class="search-breadcrumb">
                        <a-breadcrumb-item v-if="subjectIndex != -1">{{ subjectList[subjectIndex].operate_name }}
                        </a-breadcrumb-item>
                    </a-breadcrumb>
                    <a-button class="clear-btn" @click="handleClearEvent"
                        style="margin-left:20px;" size="small">清除</a-button>
                </div>
            </div>
            <div v-else class="filter-condition"></div>
            <div class="homepage-content flex">
                <div class="homepage-list flex-item">
                    <a-spin :spinning="loading" tip="Loading...">
                        <div class="meeting-item" :key="index" v-for="(item,index) in videoMeetingList" @click="handleNavigateEvent(item)">
                            <!-- @click="meetJumpDetail(item.href,item.id)" -->
                            <across-media-item :item="item">
                                <template v-slot:bottom>
                                    <span v-if="item.start_time" class="color999 fs14"><i class="med med-31shijian mr5"></i>{{item.start_time.substr(0,10)}}
                                        <span v-if="item.end_time">至 {{item.end_time.substr(0,10)}}</span>
                                    </span>
                                </template>
                            </across-media-item>
                        </div>
                        <a-empty style="margin-top:100px;" v-if="!videoMeetingList.length && !loading" />
                    </a-spin>
                    <div class="t-c mt30" v-if="videoTotal > 0">
                        <a-pagination :total="videoTotal" v-model:current="videoParams.page" :page-size="videoParams.limit" show-quick-jumper @change="handlePageChangeEvent" :hideOnSinglePage="true" />
                    </div>
                </div>
                <div class="homepage-right t-l">
                    <layout-right :config="layoutRightConfig"></layout-right>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import { videoLogo, mediaNavList } from '@/config/const'
import Header from "@/components/layout/layout-header";
import Footer from "@/components/layout/layout-footer";
import layoutRight from '@/components/layout/layout-right';
import hotTopicItem from '@/components/hot-topic-item.vue';
import acrossMediaItem from '@/components/across-media-item';
import filterTab from '@/components/filter-tab.vue';
import { jsonFormat } from '@/utils/jsonFormat';
import { meetJumpDetail , liveJumpDetail } from '@/utils/jumpPageMethods';
import { mapState } from 'vuex';
import storage from 'store'

let layoutRightConfig = {
    news: {
        show: true, // 是否显示
        methods: 'post',
        title: '最新资讯', // 标题
        moreLink: '/organization/org-infomation?committee_id=' + storage.get('commiittee_id'),
        detailLink: '/organization/org-info-detail',
        data: {
            page: 1,
            limit: 5,
        },   // 参数条件
    },
    meeting: {
        show: true, // 是否显示
        title: '本月会议', // 标题
        methods: 'get',
        moreLink: '/organization/org-meeting?committee_id=' + storage.get('commiittee_id'),
        data: {
            year: new Date().getFullYear(),
            month: new Date().getMonth() + 1,
            page: 1,
            pageSize: 6,
            is_now_year_meeting: 1,
            subject_ids: storage.get('subjectIds') || ''
        },   // 参数条件
    },
    live: { //相关直播
        show: true, // 是否显示
        methods: 'get',
        title: '相关直播', // 标题
        moreLink: '/organization/org-live?committee_id=' + storage.get('commiittee_id'),
        data: {
            page: 1,
            limit: 3,
            no_end_time: 1,
        },   // 参数条件
    },
}

export default {
    name: "MeetingVideoList",
    components: {
        Header, Footer, layoutRight, hotTopicItem, acrossMediaItem, filterTab
    },
    data() {
        return {
            logoUrl: videoLogo,
            navList: mediaNavList,//顶部菜单
            videoParams: {
                limit: 10,
                page: 1,
            },
            videoTotal: 0,
            videoMeetingList: [],
            subjectIndex: -1,
            subjectList: [],
            layoutRightConfig:{},
            loading: true,
           
        }
    },
    created() {
        this.pageInit()
    },
    computed: {
        ...mapState(['userInfo'])
    },
    methods: {
        meetJumpDetail,
        liveJumpDetail,
        getVideoMeetingList() {
            this.request.get('GetOpenVideoMeetingLive', this.videoParams).then(res => {
                let meetbackObj = {
                    news_title: 'title',
                    id: 'type_id',
                    img: 'card_img'
                }
                this.videoTotal = parseInt(res.data.count)
                this.videoMeetingList = jsonFormat(res.data.list, meetbackObj)
            })
        },
         // 清除事件
        handleClearEvent() {
            this.handleFilterEvent('subjectIndex', -1);
        },
        // 学科切换
        handleFilterEvent(key, e) {
            key && (this[key] = e);
            this.videoParams.page = 1;

            // 用户url学科或者已选学科
            let subject_ids = this.$route.query.subject_id;
            let operate_subject = subject_ids;
            // 切换后的学科
            if(this.subjectIndex != -1){
                subject_ids = this.subjectList[this.subjectIndex].subject_ids;
                operate_subject = this.subjectList[this.subjectIndex].id;
                this.videoParams.subject_id = subject_ids;
            }else{
                delete this.videoParams.subject_id;
            }
            // 获取会议视频列表
            this.getVideoMeetingList();

            // 右侧数据修改
            layoutRightConfig.news.data.operate_id = operate_subject;
            layoutRightConfig.meeting.data.subject_ids = subject_ids;
            layoutRightConfig.live.data.subject_ids = subject_ids;
            this.layoutRightConfig = JSON.parse(JSON.stringify(layoutRightConfig));
        },
        pageInit() {
            // url筛选学科
            let { subject_id } = this.$route.query;
            // 用户自己关注的学科
            subject_id = subject_id || this.userInfo.operate_subject;
            this.loading = true
            let reqArr = [
                this.$store.dispatch('getSubjectList')
            ]
            Promise.all(reqArr).then(res => {
                 // 学科列表
                let subjectList = [];
                res[0].data.forEach((item,index) => {
                    item.name = item.operate_name;
                    if(subject_id == item.id){
                        this.subjectIndex = index;
                    }
                    subjectList.push(item);
                })
                this.subjectList = subjectList;

                // 主动触发列表筛选
                this.handleFilterEvent();
                this.loading = false
            }).catch(err => {
                console.log(err)
            })
        },
        // 切换页码
        handlePageChangeEvent(page) {
            window.scrollTo(0, 0);
            this.videoParams.page = page
            this.getVideoMeetingList()
        },
        // 跳转
        handleNavigateEvent(item) {
            // type 资源类型 1 , 2 会议回放和专题 3直播回放
            let { type } = item;
            if(type == 1 || type == 2){
                this.$router.push({
                    path: '/special-detail',
                    query: {
                        type_id: item.type_id,
                        type: item.type
                    }
                })
            }else if(type == 3){
                this.liveJumpDetail(item.id);
            }
        },
    }
}
</script>

<style scoped lang="less">
.wrapper {
    min-height: 100vh;
    background: url("~@/assets/images/live-nav-top-bg.png") repeat-x;
    background-size: 1920 auto;
}

.filter-wrapper {
    background: #ffffff;
    border-radius: 10px;
    padding: 5px 15px 5px 15px;
}

.filter-condition {
    overflow: hidden;
    height: 8px;
    box-sizing: content-box;
    &.active {
        height: 50px;
        line-height: 50px;
    }
    /deep/.ant-breadcrumb>span:last-child {
        color: inherit;
    }
}

.background {
    width: 100%;
    height: 408px;
    background: linear-gradient(
        360deg,
        rgba(234, 249, 255, 0) 0%,
        #eafaff 100%
    );
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.homepage-header {
    width: 1200px;
    margin: 0 auto;

    &-title {
        max-width: 200px;
    }

    &-img {
        width: 88px;
        height: 88px;
        background: red;
    }
}
.filter-wrapper {
    width: 1240px;
    margin: 0 auto;
    background: #ffffff;
    border-radius: 10px;
    padding: 5px 15px 5px 15px;
}
.homepage-content {
    width: 1240px;
    margin: 0 auto;
    padding: 20px;
    background: #ffffff;
    border-radius: 10px;
    box-sizing: border-box;
}

.homepage-list {
    margin-right: 30px;
    min-height: 600px;
    .interview-list {
        min-height: 500px;
    }

    .bb {
        border-bottom: 1px solid #d8d8d8;
    }

    .hot-topic-box:last-child .bb {
        border-bottom: 0;
    }

    .live-item {
        margin-right: 30px;

        &:nth-child(3n) {
            margin-right: 0;
        }
    }
}

.homepage-right {
    width: 400px;
}

@media screen and (max-width: 768px) {
    .filter-wrapper,
    .homepage-list,
    .homepage-right {
        border-radius: 0;
        width: 100%;
    }
    .filter-condition.active{
        height:40px;
        line-height:40px;
        .container{
            padding:0 10px;
            display: flex;
        }
    }
    .filter-wrapper{
        padding:0;
         position: sticky;
        z-index:9;
        background-color: #fff;
        /deep/.filter-box>.mt5{
            padding-top:10px;
            margin-top:0;
        }
    }
    .homepage-list{
        min-height: 0;
    }
    .homepage-right {
        padding-top: 20px;
    }
    .homepage-content {
        border-radius: 0;
        display: block;
        width: 100%;
        padding:10px;
    }
}
</style>